import { saveAs } from 'file-saver';

/**
 * 將 base64 轉成目標類型檔案並下載
 */
// eslint-disable-next-line import/prefer-default-export
export function convertBase64AndDownload(base64, fileName) {
  // , type
  const binary = atob(base64.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }
  // , { type }
  const file = new Blob([view]);

  saveAs(file, fileName);
}
